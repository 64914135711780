<template>
  <div :class="{ 'mb-4': route.name !== 'clientAutomationFlow' }">
    <div
      class="header h-[100px] desktop:h-auto w-full p-5 desktop:p-0 fixed desktop:relative top-0 left-0 z-[3] flex flex-col items-center justify-end bg-white desktop:bg-transparent transition-all duration-300 ease-in-out"
      :class="{ 'desktop:pb-4': enableUpgrade }"
    >
      <Banner v-if="enableUpgrade" />
      <HeaderMobile class="header__inner--mobile" />
    </div>
    <slot />
  </div>
</template>
<script setup>
import Banner from '@/components/banner/Banner'
import HeaderMobile from '@/components/header/HeaderMobile'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
const store = useStore()

const enableUpgrade = computed(() => store.getters['account/enableUpgrade'])
</script>
