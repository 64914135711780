<template>
  <div
    class="h-auto absolute top-7 right-5 z-[12] cursor-pointer"
    @click="toggle"
  >
    <div
      class="h-6 w-auto inline-block relative transition-all duration-300 ease-linear z-[12] cursor-pointer"
    >
      <span
        class="block bg-black relative transition-all duration-300 ease-linear h-0.5 top-0 w-[25px]"
        :class="{ 'bg-white !top-[9px] rotate-45': showSidebar }"
      />
      <span
        class="block bg-black relative transition-all duration-300 ease-linear h-0.5 top-[8px] w-[25px]"
        :class="{ 'bg-white opacity-0': showSidebar }"
      />
      <span
        class="block bg-black relative transition-all duration-300 ease-linear h-0.5 top-[16px] w-[25px]"
        :class="{ 'bg-white !top-[5px] -rotate-45': showSidebar }"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showSidebar = computed(() => store.state.navigation.showSidebar)
const toggleSidebar = (payload) =>
  store.commit('navigation/toggleSidebar', payload)

function toggle() {
  toggleSidebar(!showSidebar.value)
}
</script>
